import React, { useEffect, useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';


import { getOwnListings } from '../SearchPage.duck';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { cleanSearchFromConflictingParams } from '../SearchPage.shared';
import { createResourceLocatorString } from '../../../util/routes';
import editIcon from './Edit icon.svg'

import css from './SelectQuote.module.css'
import { NamedLink } from '../../../components';

const SelectQuote = props => {
    const dispatch = useDispatch()
    const {
        currentUser,
        isMapVariant,
        ownListings,
        routeConfiguration,
        config,
        history,
        urlSelectedListing,
        resetQueryParams,
    } = props;

    const userType = currentUser?.attributes.profile.publicData.userType
    const isCustomer = userType == "customer"
    const [selectedListing, setSelectedListing] = useState(null)

    useEffect(() => {
        if (currentUser) {
            dispatch(getOwnListings())
        }
    }, [currentUser])

    useEffect(() => {
        setSelectedListing(urlSelectedListing)
    }, [urlSelectedListing])

    useEffect(() => {
        if (selectedListing && ownListings.length > 0) {

            const listing = ownListings.find(listing => listing.id.uuid === selectedListing)
            if (!listing) return
            const { publicData, geolocation } = listing?.attributes || {}
            const { listingFields: listingFieldsConfig } = config?.listing || {};
            const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
            let searchParams = {
                origin: geolocation,
                selectedListing
            }

            Object.keys(publicData)?.forEach(key => {
                if (key == "location" || key == "transactionProcessAlias" || key == "unitType") {
                    return
                }
                searchParams = {
                    ...searchParams,
                    [`pub_${key}`]: publicData[key],
                }
            })

            const listingCategories = config.categoryConfiguration.categories;
            const filterConfigs = {
                listingFieldsConfig,
                defaultFiltersConfig,
                listingCategories,
            };

            const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);
            resetQueryParams()

            history.push(createResourceLocatorString('ServicesSearchPage', routeConfiguration, {}, search));
        }
    }, [selectedListing, ownListings])

    return (
        <div>
            {isCustomer && isMapVariant && <div className={css.root}>
                <Select
                    className={css.listingSelector}
                    value={selectedListing}
                    onChange={(e) => {
                        setSelectedListing(e)
                    }}
                >
                    <option value={""}>Select Job</option>
                    {ownListings.map(listing => <option key={listing.id.uuid} value={listing.id.uuid}>{listing.attributes.title}</option>)}
                </Select>
                {selectedListing && <NamedLink className={css.iconWrapper}
                    name="EditListingPage"
                    params={{ id: selectedListing, slug: "", type: 'published', tab: 'details' }}
                >
                    <img src={editIcon} />
                </NamedLink>}
            </div>}
        </div>
    );
};

export default SelectQuote;
