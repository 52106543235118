import React, { useRef, useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Button, NamedLink } from '../../../components';
import { Input, Modal, notification } from 'antd';

import css from './NoSearchResultsMaybe.module.css';
import { requestServiceEmailTemplate } from './email-template';
import { sendEmail } from '../../../util/api';

const NoSearchResultsMaybe = props => {
  const {
    listingsAreLoaded,
    totalItems,
    location,
    resetAll,
    currentUser,
    isAuthenticated,
    link,
    listingType,
  } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const emailInputField = useRef(null);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.info({
      message: `Request has been sent`,
      placement: 'topRight',
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!validateEmail(emailInputField.current.input.value)) {
      setIsValidEmail(false);
      return;
    }
    sendEmailHandler();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClickRequest = () => {
    if (isAuthenticated) {
      sendEmailHandler();
      return;
    }
    showModal();
  };

  const sendEmailHandler = () => {
    const msg = {
      html: requestServiceEmailTemplate(
        link,
        isAuthenticated ? currentUser.attributes.email : emailInputField.current.input.value
      ),
      subject: 'Request For Service',
    };

    sendEmail(msg)
      .then(res => {
        openNotification();
        setIsSent(true);
      })
      .catch(err => console.log('err', err));
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return hasNoResult ? (
    <div className={css.noSearchResults}>
      {contextHolder}
      {/* <FormattedMessage id="SearchPage.noResults" /> */}
      <FormattedMessage id="SearchPage.noServiceResults" />
      {listingType === 'service' && (
        <Button disabled={isSent} onClick={onClickRequest} className={css.linkButton}>
          <FormattedMessage id={`SearchPage.${isSent ? 'requestSent' : 'requestService'}`} />
        </Button>
      )}
      <br />
      {hasSearchParams ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          <FormattedMessage id={'SearchPage.resetAllFilters'} />
        </button>
      ) : null}
      <p>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <FormattedMessage id="SearchPage.createListing" />
        </NamedLink>
      </p>
      <Modal
        title="Customer Information"
        onCancel={handleCancel}
        open={isModalOpen}
        onOk={handleOk}
        okText="Send"
      >
        <div>
          <Input
            style={{ marginTop: '10px' }}
            name="email"
            id="email"
            size="large"
            placeholder="Enter your Email..."
            ref={emailInputField}
          />
          {!isValidEmail && (
            <div style={{ color: 'red' }}>
              <FormattedMessage id={`SearchPage.invalidEmailFormat`} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
